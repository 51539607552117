import React, { Component } from 'react';
import { IndicatorContext } from "../../context/indidator";
import { API } from "../constant/api";
import { PageWelcome } from "../page-welcome";
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { styles } from 'react-select-bootstrap3';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Pagination } from "../pagination";
import { withCookies } from 'react-cookie';

const options = [
	{ value: 'chocolate', label: 'E-commerce', placeholder: 'Product name', source_type: 'tiki' },
	{ value: 'strawberry', label: 'Media', placeholder: 'Item name', source_type: 'nct' },
	{ value: 'vanilla', label: 'Service', placeholder: 'Item name', source_type: 'cungmua' }
];

const itemPerPage = 15;

/**
 *
 * @param {array} items
 * @param {number} page
 * @param {number} limit
 * @return {T[] | SharedArrayBuffer | BigUint64Array | Uint8ClampedArray | Uint32Array | Blob | Int16Array | T[] |
 *   Float64Array | Float32Array | string | Uint16Array | ArrayBuffer | Int32Array | BigInt64Array | Uint8Array |
 *   Int8Array | T[]}
 */
const getItemsByPage = (items, page, limit = itemPerPage) => {
	return items.slice(page * limit, (page + 1) * limit);
};

/**
 *
 * @param {number} s
 * @return {*}
 */
const score = (s) => {
	return (
		<span className={'item-score'}>{s ? 'Score: ' + s.toFixed(5) : ''}</span>
	)
};

function NoData() {
	return (
		<div className="no-data">
			No data
		</div>
	)
}

export class RecommendationSystem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOption: null,
			selectedItemSearch: null,
			selectedRelevantItem: null,
			inputValue: '',
			searchResult: {
				items: [],
				currentPage: 0,
				pages: 0
			},
			relevant: {
				items: [],
				currentPage: 0,
				pages: 0
			},
			forYou: {
				items: [],
				currentPage: 0,
				pages: 0
			},
			history: {
				items: [],
				currentPage: 0,
				pages: 0
			}
		};

		this.onSearchResultPageChanged = this.onSearchResultPageChanged.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onSearchResultPageChanged(page) {
		this.setState((prevState, props) => {
			return {
				...prevState,
				searchResult: {
					...prevState.searchResult,
					currentPage: page
				}
			}
		});
	}

	onPageChanged(keyInState, page) {
		this.setState(prevState => {
			return {
				...prevState,
				[keyInState]: {
					...prevState[keyInState],
					currentPage: page
				}
			}
		});
	}

	generateBody() {
		return {
			"search": 1,
			"source_type": this.state.selectedOption.source_type,
			"kw": this.state.inputValue,
			"top_n": 1000
		};
	}

	onClickBtnGo() {
		if (!this.state.selectedOption || !this.state.inputValue) {
			return;
		}

		const body = this.generateBody();
		axios.post(API.getRecommendation, body)
			.then(res => {

				this.setState(
					{
						searchResult: {
							items: res.data.filter(item => !!item.itemName),
							currentPage: 0,
							pages: Math.ceil(res.data.length / itemPerPage)
						},
						relevant: {
							currentPage: 0,
							pages: 0,
							items: []
						},
						forYou: {
							currentPage: 0,
							pages: 0,
							items: []
						}
						// history: {
						// 	currentPage: 0,
						// 	pages: 0,
						// 	items: []
						// }
					}
				);
			})
			.catch(err => {
				console.error('onClickBtnGo', err);
			});
	}

	onInputChanged(evt) {
		this.setState({
			inputValue: evt.target.value
		});
	}

	onKeyPressInputText(evt) {
		if (evt.which === 13) {
			this.onClickBtnGo();
		}
	}

	handleChange(selectedOption) {
		this.setState({
			selectedOption: selectedOption
		});
	}

	getCookieNameBySourceType() {
		return this.state.selectedOption.source_type + "_his";
	}

	clearCookie() {
        if (!this.state.selectedOption) {
            window.confirm('You should choose category first.');
            return;
        }

        const confirmed = window.confirm('Do you want to delete your history?');
		if (confirmed) {
			const { cookies } = this.props;
			const cookieHisName = this.getCookieNameBySourceType();
			cookies.remove(cookieHisName);
			this.resetStateWhenClearCookie();
		}
	}

	resetStateWhenClearCookie() {
		this.setState((prevState) => {
			return {
				selectedItemSearch: null,
				selectedRelevantItem: null,
				relevant: {
					items: [],
					currentPage: 0,
					pages: 0
				},
				forYou: {
					items: [],
					currentPage: 0,
					pages: 0
				},
				history: {
					items: [],
					currentPage: 0,
					pages: 0
				}
			};
		});
	}

	saveCookie(item) {
		// save cookies
		// add item in list items: item1,item2,item3...

		const { cookies } = this.props;
		const cookieHisName = this.getCookieNameBySourceType();
		let cookieHisValue = cookies.get(cookieHisName);
		if (cookieHisValue == null)
			cookies.set(cookieHisName, item.itemId);
		else {
			cookieHisValue = cookieHisValue.split(',');
			if (cookieHisValue.indexOf(item.itemId) === -1) {
				cookieHisValue.push(item.itemId);
				cookies.set(cookieHisName, cookieHisValue.join(','));
			}
		}
	}

	onClickItemSearchResult(item, saveSelectedItem = true, isSearchResult = true) {

		if (item.itemType === 'PRD' || isSearchResult) {
			this.saveCookie(item)
		}

		if (saveSelectedItem) {
			this.setState({
				selectedItemSearch: item,
				selectedRelevantItem: item
			});
		} else {
			this.setState({
				selectedRelevantItem: item
			});
		}

		console.log(item, saveSelectedItem);

		// call api get relevant
		this.sendRequestGetRelevant(item);

		// call api get recommend for you
		this.sendRequestGetRecommendForYou(item);

		// call api get history
		this.sendRequestGetHistory(item);
	}

	sendRequestGetRelevant(itemSearchResult) {
		const body = {
			"source_type": this.state.selectedOption.source_type,
			"top_n": 300
		};

		if (itemSearchResult.itemType === 'FID') {
			body.userId = itemSearchResult.itemId;
			body.by_user = '1';
		} else {
			body.itemId = itemSearchResult.itemId;
			body.by_item = '1'
		}

		axios.post(API.getRecommendation, body)
			.then(res => {
				this.setState({
					relevant: {
						items: res.data,
						currentPage: 0,
						pages: Math.ceil(res.data.length / itemPerPage)
					}
				});
			})
			.catch(err => {
				console.error('sendRequestGetRelevant', err);
			});
	}

	sendRequestGetRecommendForYou(itemSearchResult) {
		const { cookies } = this.props;

		const body = {
			"source_type": this.state.selectedOption.source_type,
			"by_list_items": "1",
			"top_n": 300
		};

		const hisValue = cookies.get(this.getCookieNameBySourceType())
		if (hisValue == null) {
			body.list_itemIds=[]
		} else {
			body.list_itemIds=hisValue.split(",")
		}

		axios.post(API.getRecommendation, body)
			.then(res => {
				this.setState({
					forYou: {
						items: res.data,
						currentPage: 0,
						pages: Math.ceil(res.data.length / itemPerPage)
					}
				});
			})
			.catch(err => {
				console.error('sendRequestGetRecommendForYou', err);
			});
	}

	sendRequestGetHistory(itemSearchResult) {
		const { cookies } = this.props;
		// 100001626679842
		var cookieHisValue = cookies.get(this.getCookieNameBySourceType());
		if (cookieHisValue == null) cookieHisValue = '';

		const body = {
			"source_type": this.state.selectedOption.source_type,
			"list_itemIds": cookieHisValue,
			"top_n": 300
		};

		axios.post(API.getRecHis, body)
			.then(res => {
				this.setState({
					history: {
						items: res.data,
						currentPage: 0,
						pages: Math.ceil(res.data.length / itemPerPage)
					}
				});
			})
			.catch(err => {
				console.error('sendRequestGetHistory', err);
			});
	}

	renderSearchResult() {
		// if (this.state.searchResult.items.length === 0) {
		// 	return;
		// }

		const renderItems = getItemsByPage(this.state.searchResult.items, this.state.searchResult.currentPage);

		return (
			<Row className={'margin-top-20'}>
				<Col md={8} className={'col-md-offset-2'}>
					<h3 className={'text-info'}>Search Result</h3>

					{
						renderItems.length === 0 ? 
							<NoData />
						:
						<div className="list-group">
							{
								renderItems.map((s, index) => {
									let extraClass = '';
									if (this.state.selectedItemSearch && this.state.selectedItemSearch.itemId === s.itemId) {
										extraClass = 'active';
									}

									return (
										<button type="button"
														onClick={() => {this.onClickItemSearchResult(s)}}
														key={index}
														style={{ 'padding': '5px' }}
														className={'list-group-item ' + extraClass}>
											{s.itemName}
										</button>
									)
								})
							}
						</div>
					}

					{
						this.state.searchResult.pages <= 1 ?
						<></>
						:
						<Pagination total={this.state.searchResult.pages}
											onPageChanged={this.onSearchResultPageChanged}
											current={this.state.searchResult.currentPage}/>
					}
				</Col>
			</Row>
		)
	}

	renderOtherList(title, keyInState, clickAble = false) {
		// if (this.state[keyInState].items.length === 0) {
		// 	return;
		// }

		const renderItems = getItemsByPage(this.state[keyInState].items, this.state[keyInState].currentPage);

		return (
			<Row className={'margin-top-40'}>
				<Col md={8} className={'col-md-offset-2'}>
					<h3 className={'text-info'}>
						{title}
						{
							keyInState === 'history' ?
								<button className={'btn btn-warning'}
												onClick={() => this.clearCookie()}
												style={{ float: 'right' }}>
									< i className={'fa fa-trash'}></i>
									Clear cookie
								</button>
								: ''
						}
					</h3>
					{
						renderItems.length === 0 ? 
							<NoData />
						:
						<div className="list-group">
							{
								clickAble ?
									renderItems.map(s => {
										return (
											<button key={s.itemId}
															onClick={() => this.onClickItemSearchResult(s, false, false)}
															type={'button'}
															style={{ 'padding': '5px' }}
															className={'list-group-item'}>
												<span className={'item-name'}>{s.itemName ? s.itemName : s.itemId}</span>
												{score(s.score)}
											</button>
										)
									})
									:
									renderItems.map(s => {
										return (
											<li key={s.itemId}
													style={{ 'padding': '5px' }}
													className={'list-group-item'}>
												<span className={'item-name'}>{s.itemName ? s.itemName : s.itemId}</span>
												{score(s.score)}
											</li>
										)
									})
							}
						</div>
					}

					{
						this.state[keyInState].pages <= 1 ?
							<></>
						:
						<Pagination total={this.state[keyInState].pages}
												onPageChanged={(page) => {this.onPageChanged(keyInState, page)}}
												current={this.state[keyInState].currentPage}/>
					}
				</Col>
			</Row>
		)
	}

	render() {
		const relevantSectionName = this.state.selectedRelevantItem ?
			(this.state.selectedRelevantItem.itemName || this.state.selectedRelevantItem.itemId) : '';

		return (
			<div>
				<PageWelcome>
					<h2>AI4U Recommendation System</h2>
				</PageWelcome>

				<div className={'container'}>
					<Row className={'margin-top-20'}>
						<Col md={4} sm={6} className={'col-md-offset-2 col-xs-6'}>
							<label>
								Select category
							</label>
							<Select
								options={options}
								onChange={this.handleChange.bind(this)}
								styles={styles}
							/>
						</Col>
						<Col md={4} sm={6} className={'col-xs-6'}>
							<label>
								Input product name
							</label>
							<div className="input-group">
								<input type="text"
											 value={this.state.inputValue}
											 onChange={this.onInputChanged.bind(this)}
											 onKeyPress={this.onKeyPressInputText.bind(this)}
											 className="form-control"
											 placeholder={this.state.selectedOption ? this.state.selectedOption.placeholder : ''}/>
								<span className="input-group-btn">
						<button className="btn btn-default"
										onClick={this.onClickBtnGo.bind(this)}
										type="button">
						Go!
						</button>
						</span>
							</div>
						</Col>
					</Row>

					{this.renderSearchResult()}
					{this.state.selectedItemSearch ? this.renderOtherList('Relevant items of ' + relevantSectionName, 'relevant', true) : ''}
					{this.renderOtherList('Recommend for you', 'forYou', true)}
					{this.renderOtherList('Your history', 'history')}
				</div>
			</div>
		)
	}
}

RecommendationSystem.contextType = IndicatorContext;
export default withCookies(RecommendationSystem);
