import React, { Component } from 'react';
import logo from '../../images/AI4U.png';
import { Link } from 'react-router-dom';
import { Links } from "../constant/nav-links";

export class Footer extends Component {
	render() {
		const _links = JSON.parse(JSON.stringify(Links));
		_links.reverse();

		return (
			<section id="footer" className="footer sections">
				<div className="container">
					<div className="row">
						<div className="main_footer wow fadeInDown" data-wow-duration="2s">
							<div className="col-sm-2 col-xs-12">
								<div className="footer_logo">
									<a href="/"><img src={logo} alt=""/></a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="footer_bottom_area">
							<div className="col-sm-9 col-xs-12">
								<div className="fo_bottom_right">
									<div className="row">
										<div className="col-sm-6 col-xs-12">
											<div className="single_right_footer wow fadeIn" data-wow-duration="1s">
												<p>Artificial Intelligence for You</p>
												<a>Implemented by : <strong>AI Rookies VN</strong></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3 col-xs-12">
								<div className="fo_bottom_socail wow fadeIn" data-wow-duration="2.3s">Contact us
									<a href="tel:0907246514"><i className="fa fa-phone"> Tel: 0907246514</i></a>
									<a href="mailto:contact@ai4u.io"><i className="fa fa-envelope"></i>Mail: contact@ai4u.io</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
