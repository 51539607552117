import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

export class Blog extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<>
				<PageWelcome>
					<h2>Blog</h2>
				</PageWelcome>
			</>
		)
	}
}
