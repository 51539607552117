import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

// images
import whatWeDoImg from '../../images/whatwedo.jpg';
import maoImg from '../../images/MAO.jpg';
import thuImg from '../../images/THU.jpg';
import lamImg from '../../images/LAM.jpg';
import linhImg from '../../images/LINH.jpg';
import ourStrength from '../../images/our-strength.png';

// advisors
import hungImg from '../../images/TranCongHung.jpg';
import dinhImg from '../../images/anh_Dinh.jpg';
import phuImg from '../../images/PHU.jpg';
import anImg from '../../images/an_tran.png';

export class About extends Component {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2>ABOUT US</h2>
					<p style={{ fontSize: '30px' }}>Our mission is to create values for clients by extracting novel actionable
						insights by using advanced data science techniques.</p>
				</PageWelcome>

				<section id="domain" className="domainsearce">
					<div className="container">
						<div className="row">
							<div className="col-sm-10 col-sm-offset-1">
								<div className="main_somainsearrce">
									<div className="head_title text-center wow fadeInDown animated animated" data-wow-duration="1s"
											 style={{ visibility: 'visible', animationDuration: '1s' }}>
										<h1 style={{ color: '#14a9d7', fontWeight: 500, textTransform: 'uppercase', padding: '30px 0' }}>Our belief</h1>
										<p style={{ color: 'black', fontSize: '20px', wordSpacing: '6px' }}>
											Data is the most valuable asset for enterprise and data-driven organizations are leading the way.
											We help enterprises use data to make better business decisions, deliver more values for their
											customers by utilizing powerful Artificial Intelligence.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="whatwedo" className="domainsearce">
					<div className="container">
						<div className="row">
							<div className="col-sm-10 col-sm-offset-1">
								<div className="main_somainsearrce">
									<div className="head_title text-center wow fadeInDown animated animated" data-wow-duration="1s"
											 style={{ visibility: 'visible', animationDuration: '1.5s' }}>
										<h1 style={{ color: '#14a9d7', fontWeight: 500, textTransform: 'uppercase', padding: '30px 0' }}>Our strength</h1>
										<p style={{ color: 'black', fontSize: '20px', wordSpacing: '6px' }}>
											What makes our expertise unique is the fusion of the technical aspects (IT, statistics & data
											mining) with the business knowledge and insights. </p>
									</div>
									<div className="single_dotcom_serce text-center">
										<img src={ourStrength} width="500" alt=""/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="service" className="service">
					<div className="container">
						<div className="col-sm-10 col-sm-offset-1">
							<div className="main_somainsearrce">
								<div className="head_title text-center wow fadeInDown animated animated" data-wow-duration="1s"
										 style={{ visibility: 'visible', animationDuration: '1.5s' }}>
									<h1 style={{ color: '#14a9d7', fontWeight: 500, textTransform: 'uppercase', padding: '30px 0' }}>
										Meet our team
									</h1>
									<p style={{ color: 'black', 'textAlign': 'center', fontSize: '20px' }}>We bring together expertise in Machine
										Learning, Big
										Data and Marketing.</p>
									<p style={{ color: 'black', 'textAlign': 'center', fontSize: '20px' }}>Our team just won the Third prize (3/1395
										teams) of
										CIKM AnalytiCup 2017, a part of CIKM (Top 3 conferences in Data Mining), sponsored by
										Alibaba.</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="main_service text-center">
								<div className="col-md-3 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration=".6s"
											 style={{ visibility: 'visible', animationDuration: '0.6s' }}>
										<div className="single_service_icon icon_two">
											<a href=""><img src={maoImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Nguyễn Xuân Mão</h4>
											<h6 style={{ fontWeight: 'bold' }}>Data Scientist</h6>
											<p style={{ color: 'black', textAlign: 'justify' }}>Mao has 6 years of experience in Computer
												vision,
												Machine Learning, Deep Learning, Recommender System, Sentiment Analysis,.. He holds a master
												degree in Computer Science from John von Neumann Institute. He got 3rd CIKM AnalytiCup 2017.</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration="1.7s"
											 style={{ visibility: 'visible', animationDuration: '1.7s' }}>
										<div className="single_service_icon icon_three">
											<a href=""><img src={thuImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Võ Trọng Thư</h4>
											<h6 style={{ fontWeight: 'bold' }}>Data Scientist</h6>
											<p style={{ color: 'black', textAlign: 'justify' }}>Thu has years of experience in Machine
												Learning, Deep
												Learning, Retail BI, Enterprise Architecture. He holds a master degree in Computer Science from
												John von Neumann Institute Vietnam. He got 3rd CIKM AnalytiCup 2017.</p>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration="2s"
											 style={{ visibility: 'visible', animationDuration: '2s' }}>
										<div className="single_service_icon icon_four">
											<a href=""><img src={lamImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Hoàng Viết Lãm</h4>
											<h6 style={{ fontWeight: 'bold' }}>Big Data Engineer</h6>
											<p style={{ color: 'black', textAlign: 'justify' }}>Lam has hands-on experience in Big Data with
												log game,
												Ad and telco data from India, Indonesia and Vietnam and total 5 years working as software
												architect for large-scale enterprise software solutions.</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration="2.4s"
											 style={{ visibility: 'visible', animationDuration: '2.4s' }}>
										<div className="single_service_icon icon_two">
											<a href=""><img src={linhImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Nguyễn Thị Phương Linh</h4>
											<h6 style={{ fontWeight: 'bold' }}>Marketer</h6>
											<p style={{ color: 'black', textAlign: 'justify' }}>With 8 years of experience in consumer insight
												research, Linh has worked with a variety of clients in FMCG, finance and insurance. She hold a
												master degree in Marketing Analysis in Belgium.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section id="service" className="service">
					<div className="container">
						<div className="col-sm-10 col-sm-offset-1">
							<div className="main_somainsearrce">
								<div className="head_title text-center wow fadeInDown animated animated" data-wow-duration="1s"
										 style={{ visibility: 'visible', animationDuration: '1.5s' }}>
									<h1 style={{ color: '#14a9d7', fontWeight: 500, textTransform: 'uppercase', padding: '30px 0' }}>
										Our technical advisors
									</h1>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="main_service text-center">
								<div className="col-md-4	 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration=".6s"
											 style={{ visibility: 'visible', animationDuration: '0.6s' }}>
										<div className="single_service_icon icon_two">
											<a href=""><img src={anImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>An Tran</h4>
											<h6 style={{ fontWeight: 'bold' }}>Phd in Computer Vision</h6>
											<p style={{ color: 'black', textAlign: 'center' }}>
												Computer vision researcher at Grab
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration="1.7s"
											 style={{ visibility: 'visible', animationDuration: '1.7s' }}>
										<div className="single_service_icon icon_three">
											<a href=""><img src={dinhImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Dinh Nguyen</h4>
											<h6 style={{ fontWeight: 'bold' }}>Phd in Mathematics</h6>
											<p style={{ color: 'black', textAlign: 'center' }}>
												Data Scientist at Cylance Inc
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-4 col-sm-6 col-xs-12">
									<div className="single_service wow fadeIn animated animated" data-wow-duration="2s"
											 style={{ visibility: 'visible', animationDuration: '2s' }}>
										<div className="single_service_icon icon_four">
											<a href=""><img src={hungImg} width="128" height="128"/></a>
										</div>
										<div className="single_service_deatels">
											<h4>Hung Tran</h4>
											<h6 style={{ fontWeight: 'bold' }}>Ph.D Mathematics</h6>
											<p style={{ color: 'black', textAlign: 'center' }}>
												Assistant Professor at The University of Georgia
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
