import React, { Component } from 'react';
import { IndicatorContext } from "../context/indidator";

export class PageWelcome extends Component {
	constructor(props) {
			super(props);
		this.myRef = React.createRef();
	}

	componentDidMount() {
		this.context.setIntroHeight(this.myRef.current.offsetHeight);
	}

	render() {

		return (
			<IndicatorContext.Consumer>
				{
					() => {
						return (
							<section id="home"
											 ref={this.myRef}
											 className="home intro-section">
								<div className="home-overlay-fluid">
									<div className="container">
										<div className="row">
											<div className="main_slider_area">
												<div className="slider">
													<div className="single_slider wow fadeIn animated" data-wow-duration="2s">
														{this.props.children}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						)
					}
				}
			</IndicatorContext.Consumer>
		)
	}
}

PageWelcome.contextType = IndicatorContext;
