import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { IndicatorContext } from "../../context/indidator";
import { PageWelcome } from "../page-welcome";
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import bgProduct from '../../images/product.png';
import bg1 from '../../images/map_1.png';
import bg2 from '../../images/map_2.png';

import icon1 from '../../images/1.png';
import icon2 from '../../images/2.png';
import icon3 from '../../images/3.png';
import icon4 from '../../images/4.png';
import icon5 from '../../images/5.png';
import icon6 from '../../images/6.png';
import icon8 from '../../images/8.png';
import icon9 from '../../images/9.png';

export class Index extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2>AI4U Recomm</h2>
					<p>
						A reliable top-notch recommendation system as-a-service for all size businesses
					</p>
				</PageWelcome>

				{/*<section id="clients" className="comments">
					<div className="overlay-fluid-block">
						<div className="container">
							<div className="row">
								<div className="main_comments wow fadeInUp animated" data-wow-duration="1.5s">

									<Carousel showThumbs={false}
														autoPlay={false}
														swipeable={true}
														infiniteLoop={true}
														emulateTouch={true}
														showStatus={false}
														showArrows={false}>
										<div className="single_comments text-center">
											<p>Scalability</p>
										</div>
										<div className="single_comments text-center">
											<p>Fast Deployment</p>
										</div>
										<div className="single_comments text-center">
											<p>Flexibility</p>
										</div>
									</Carousel>
								</div>
							</div>
						</div>
					</div>
				</section>*/}

				<div className="container"
						 style={{ marginTop: '30px' }}>
					<div className="row">
						<div className="col-sm-12">
							<h1 style={{ textAlign: 'center', color: '#14a9d7', fontWeight: 500 }}>
								Why choosing AI4U Recomm
							</h1>
						</div>
					</div>
				</div>

				<div className={'container'}
						 style={{ marginTop: '100px' }}>
					<div className="content vs-2-5 vsb-6 text-center text-lg-left recommender">
						<div className="text col-12 col-lg-7 col-xl-6 col-md-6">
							<h2>
								Values
							</h2>

							<div className="points row text-left">
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"> <img src={icon1} alt=""/></i>
									<span>
										Leverage profitability by offering relevant products / services (cross-sell, up-sell)
									</span>
								</div>
								<div className="point col-12 col-sm-12"><i className="poly poly-green"><img
									src={icon3}
									alt=""/></i>
									<span>
										Enhance users experience by assisting users in finding information
									</span>
								</div>
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"><img
										src={icon5} alt=""/></i>
									<span>
										Minimize the cost of advertising by sending special offers to right customer
									</span>
								</div>
							</div>
						</div>

						<div className="image col-12 col-md-6 offset-md-3 offset-lg-0 col-lg-5 col-xl-6">
							<div className={'bg-intro'}>
								<img src={bg1} className="mw-100 wm-75" alt="" width="483"/>
							</div>
						</div>
					</div>
				</div>

				<div className={'container'}
						 style={{ marginTop: '100px' }}>
					<div className="content vs-2-5 vsb-6 text-center text-lg-left recommender">
						<div className="image col-12 col-md-6 offset-md-3 offset-lg-0 col-lg-5 col-xl-6">
							<div className={'bg-intro'}>
								<img src={bg2}
										 className="mw-100 wm-75"
										 alt=""
										 width="483"/>
							</div>
						</div>

						<div className="text col-12 col-lg-7 col-xl-6 col-md-6">
							<h2>
								Features
							</h2>

							<div className="points row text-left">
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"> <img src={icon2} alt=""/></i>
									<span>
										Real-time recommendation services
									</span>
								</div>
								<div className="point col-12 col-sm-12"><i className="poly poly-green"><img
									src={icon4}
									alt=""/></i>
									<span>
										Domain independent and cross platform
									</span>
								</div>
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"><img
										src={icon9} alt=""/></i>
									<span>
										Scalable Solution
									</span>
								</div>
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"><img
										src={icon8} alt=""/></i>
									<span>
										Information privacy
									</span>
								</div>
								<div className="point col-12 col-sm-12">
									<i className="poly poly-green"><img
										src={icon6} alt=""/></i>
									<span>
										Quick and easy integration
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container" style={{marginTop: '100px'}}>
					<div className="row">
						<div className="col-sm-6">
							<YouTube
								videoId="D6awmconvts"
								opts={{
									width: '100%'
								}}
							/>
						</div>

						<div className="col-sm-6">
							<YouTube
								videoId="HSim6aAjYVk"
								opts={{
									width: '100%'
								}}
							/>
						</div>
					</div>
				</div>

				<div className="container">
					<div className="col-sm-12"
							 style={{ textAlign: 'center', marginTop: '100px' }}>
						<h3>
							We offer you a free trial up to 06 months?
						</h3>

						<Link to={'/live-demo'}
									style={{ display: 'inline-block' }}
						>
							<button className={'btn btn-default'}>
								Live demo
							</button>
						</Link>

						<Link to={'/request-trial'}
									style={{ display: 'inline-block', marginLeft: '10px' }}>
							<button className={'btn btn-default'}>
								Request trial
							</button>
						</Link>
					</div>
				</div>

				{/*<section id="service" className="service">*/}
				{/*<div className="container">*/}
				{/*<div className="row">*/}
				{/*<div className="main_service text-center">*/}
				{/*<div className="col-md-3 col-sm-6 col-xs-12">*/}
				{/*<div className="single_service wow fadeIn" data-wow-duration=".6s">*/}
				{/*<div className="single_service_icon icon_one">*/}
				{/*<a href=""><i className="fa fa-flash"></i></a>*/}
				{/*</div>*/}
				{/*<div className="single_service_deatels">*/}
				{/*<h4>RECOMMENDATION</h4>*/}
				{/*<p>Increase your customer's happiness by suggesting them what they want.</p>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*<div className="col-md-3 col-sm-6 col-xs-12">*/}
				{/*<div className="single_service wow fadeIn" data-wow-duration="1.2s">*/}
				{/*<div className="single_service_icon icon_two">*/}
				{/*<a href=""><i className="fa fa-skype"></i></a>*/}
				{/*</div>*/}
				{/*<div className="single_service_deatels">*/}
				{/*<h4>DEMOGRAPHIC SYSTEM</h4>*/}
				{/*<p>Create your customer's profile in an easiest way ever.</p>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*<div className="col-md-3 col-sm-6 col-xs-12">*/}
				{/*<div className="single_service wow fadeIn" data-wow-duration="1.7s">*/}
				{/*<div className="single_service_icon icon_three">*/}
				{/*<a href=""><i className="fa fa-arrows"></i></a>*/}
				{/*</div>*/}
				{/*<div className="single_service_deatels">*/}
				{/*<h4>POWER MARKETING</h4>*/}
				{/*<p>Power your marketing campaign by Artificial Intelligence.</p>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*<div className="col-md-3 col-sm-6 col-xs-12">*/}
				{/*<div className="single_service wow fadeIn" data-wow-duration="2s">*/}
				{/*<div className="single_service_icon icon_four">*/}
				{/*<a href=""><i className="fa fa-comments"></i></a>*/}
				{/*</div>*/}
				{/*<div className="single_service_deatels">*/}
				{/*<h4>TOPIC MODELING</h4>*/}
				{/*<p>Explore what your customers reading on the Social Network.</p>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</div>*/}
				{/*</section>*/}
			</div>
		)
	}
}

Index.contextType = IndicatorContext;
