export const Links = [
	{title: 'HOME',to: '/'},
	{
		title: 'SOLUTIONS',
		children: [
			{title: 'Ai4u Recomm',to: '/solutions/recommender'},
			{title: 'Customer prediction',to: '/solutions/customer-prediction'},
			{title: 'Interest solution',to: '/solutions/interest-solution'},
			// {title: 'Topic modeling',to: '/solutions/topic-modeling'}
		]
	},
	// {title: 'USE CASES', to: '/'},
	{
		title: 'DEMO',
		children: [
			{title: 'Live demo', to: '/live-demo'},
			{title: 'Request trial', to: '/request-trial'}
		]
	},
	// {title: 'BLOG',to: '/blog'},
	{title: 'ABOUT',to: '/about'},
];
