import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

export class InterestSolution extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2 style={{ fontSize: '32px' }}>
						Describing the profile of potential customers
					</h2>
				</PageWelcome>
			</div>
		)
	}
}
