import React, { Component } from 'react';

export class Pagination extends Component {

	constructor(props) {
		super(props);

		this.state = {
			current: 0,
			pages  : []
		}
	}

	componentDidMount() {
		this.setState({
			current: this.props.current,
			pages  : new Array(this.props.total).fill('')
		});
	}

	onChangePage(value) {
		switch (value) {
			case 'NEXT':
				this.props.onPageChanged(this.props.current + 1);
				this.setState((prevState) => {
					return {
						current: prevState.current + 1
					}
				});
				break;
			case 'PREV':
				this.props.onPageChanged(this.props.current - 1);
				this.setState((prevState) => {
					return {
						current: prevState.current - 1
					}
				});
				break;
			case 'FIRST':
				this.props.onPageChanged(0);
				this.setState({
					current: 0
				})
				break;
			case 'LAST':
				this.props.onPageChanged(this.props.total - 1);
				this.setState({
					current: this.props.total - 1
				});
				break;
			default:
				this.props.onPageChanged(value);
				this.setState({
					current: value
				});
				break;
		}
	}

	getPageRender() {
		let pagesHtml = [];
		const currentIndex = this.state.current;
		const pages = this.state.pages;
		let tempPages = [];
		const visibleSettings = {
			prevPages: false,
			nextPages: false
		};

		if (pages.length <= 5) {
			visibleSettings.prevPages = false;
			visibleSettings.nextPages = false;

			const tmpArray = [...pages].map((value, index) => {
				return index;
			});
			tempPages = [...tmpArray];
		} else {
			visibleSettings.prevPages = true;
			visibleSettings.nextPages = true;

			if (currentIndex < 3) {
				visibleSettings.prevPages = false;
				tempPages = [0, 1, 2, 3, 4];
			} else if (currentIndex > pages.length - 3) {
				visibleSettings.nextPages = false;
				tempPages = [
					pages.length - 5,
					pages.length - 4,
					pages.length - 3,
					pages.length - 2,
					pages.length - 1,
				];
			} else {
				tempPages = [
					currentIndex - 2,
					currentIndex - 1,
					currentIndex,
					currentIndex + 1,
					currentIndex + 2,
				];
			}
		}

		pagesHtml = tempPages.map((v, i) => {
			return (
				<li key={i}
						style={{cursor: 'pointer'}}
						className={this.state.current === v ? 'active' : ''}
						onClick={() => {this.onChangePage(v)}}>
					<a>
						{v + 1}
					</a>
				</li>
			)
		});

		if (visibleSettings.prevPages) {
			pagesHtml.unshift(
				<li key={'prev'}
						style={{cursor: 'pointer'}}
						onClick={() => {this.onChangePage('PREV')}}>
					<a>
						...
					</a>
				</li>
			)
		}

		if (visibleSettings.nextPages) {
			pagesHtml.push(
				<li key={'next'}
						style={{cursor: 'pointer'}}
						onClick={() => {this.onChangePage('NEXT')}}>
					<a>
						...
					</a>
				</li>
			)
		}

		return pagesHtml;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.current !== this.props.current) {
			this.setState({
				current: this.props.current,
				pages  : new Array(this.props.total).fill('')
			});
		}
	}

	render() {

		return (
			<nav aria-label="Page navigation" style={{textAlign: 'center'}}>
				<ul className="pagination">
					<li onClick={() => {this.onChangePage('FIRST')}} style={{cursor: 'pointer'}}>
						<a aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</a>
					</li>
					{this.getPageRender()}
					<li onClick={() => {this.onChangePage('LAST')}} style={{cursor: 'pointer'}}>
						<a aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</a>
					</li>
				</ul>
			</nav>
		)
	}
}
