import React, { Component } from 'react';
import { API } from "../constant/api";
import { PageWelcome } from "../page-welcome";
import axios from 'axios';

export class RequestDemo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			name : '',
			phone: '',
			website: ''
		}
	}

	setInput(name, value) {
		this.setState({
			[name]: value
		});
	}

	onSubmitForm(e) {
		e.preventDefault();

		axios.post(API.requestDemo, this.state)
			.then((response) => {
				alert('Thanks for you submit');
			})
			.catch(err => {
				console.error(err);
			});
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2>REQUEST DEMO</h2>
				</PageWelcome>
				<div className={'container'}
						 style={{ marginTop: '30px' }}>
					<div className="row">
						<div className="col-sm-12 col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-2">
							<h2>Request for 6-month trial</h2>
							<p>Want to apply AI4U Recommender on your own data? Please give us your contact for a further support</p>
							<form className="form-horizontal"
										onSubmit={this.onSubmitForm.bind(this)}>
								<div className="form-group">
									<label htmlFor="email" className="col-sm-4 control-label">Email</label>
									<div className="col-sm-6">
										<input type="email"
													 value={this.state.email}
													 onChange={(e) => {this.setInput('email', e.target.value)}}
													 className="form-control"
													 required
													 id="email"
													 placeholder="Email"/>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="name" className="col-sm-4 control-label">Name</label>
									<div className="col-sm-6">
										<input type="text"
													 value={this.state.name}
													 onChange={(e) => {this.setInput('name', e.target.value)}}
													 className="form-control"
													 id="name"
													 required
													 placeholder="Name"/>
									</div>
								</div>

								<div className="form-group">
									<label htmlFor="phone" className="col-sm-4 control-label">Phone</label>
									<div className="col-sm-6">
										<input type="text"
													 value={this.state.phone}
													 onChange={(e) => {this.setInput('phone', e.target.value)}}
													 className="form-control"
													 id="phone"
													 //required
													 placeholder="Phone"/>
									</div>
								</div>

								<div className="form-group">
									<label htmlFor="website" className="col-sm-4 control-label">Website</label>
									<div className="col-sm-6">
										<input type="text"
													 value={this.state.website}
													 onChange={(e) => {this.setInput('website', e.target.value)}}
													 className="form-control"
													 id="website"
													 //required
													 placeholder="Website"/>
									</div>
								</div>

								<div className="form-group">
									<div className="col-sm-offset-4 col-sm-4 text-center">
										<button type="submit"
														className="btn btn-primary">Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
