import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

export class CustomerPrediction extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2 style={{fontSize: '32px'}}>
						Identifying valued customers to retain their loyalty or acquire new customers
					</h2>
				</PageWelcome>
			</div>
		)
	}
}
