import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

export class PowerMarketing extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2>POWER MARKETING TOOL</h2>
					<p>Contact Us for live demo 0936266749</p>
				</PageWelcome>
			</div>
		)
	}
}
