import React, { Component } from 'react';
import { PageWelcome } from "../page-welcome";

export class TopicModelling extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div>
				<PageWelcome>
					<h2>TOPIC MODELING SYSTEM</h2>
					<p>Contact Us for live demo 0936266749</p>
				</PageWelcome>

				<section id="topic" className="domainsearce">
					<div className="container">
						<div className="row">
							<div className="col-sm-10 col-sm-offset-1">
								<div className="main_somainsearrce">
									<div className="head_title text-center wow fadeInDown animated" data-wow-duration="1s"
											 style={{ visibility: 'visible', animationDuration: '1s', animationName: 'fadeInDown' }}>
										<h3>Your request is about</h3>
										<p>http://www.24h.com.vn/bong-da-ngoai-hang-anh/mu-tranh-vuong-ba-mourinho-cay-quan-tinh-nhue-nho-sieu-du-bi-c149a900899.html</p>
									</div>
									<div className="sercelist wow fadeIn animated" data-wow-duration="2s"
											 style={{ visibility: 'visible', animationDuration: '2s', animationName: 'fadeIn' }}>
										<div className="single_dotcom_serce text-center">
											<ul className="list-inline">
												<li className={'line-break'}></li>
												<li>
													<p> Đá banh </p>
													<p>Score:0.53</p>
												</li>

												<li>
													<p> Thể thao nói chung </p>
													<p>Score:0.13</p>
												</li>

												<li>
													<p> Bất động sản </p>
													<p>Score:0.06</p>
												</li>

												<li>
													<p> An ninh-pháp luật-tội phạm </p>
													<p>Score:0.05</p>
												</li>

												<li className={'line-break'}></li>

												<li>
													<p> Viễn thông </p>
													<p>Score:0.04</p>
												</li>

												<li>
													<p> Phần cứng-Máy tính bàn-Máy tính xách tay </p>
													<p>Score:0.04</p>
												</li>

												<li>
													<p> Sức khỏe-phòng và chữa bệnh </p>
													<p>Score:0.04</p>
												</li>

												<li>
													<p> Finance-TheRich </p>
													<p>Score:0.04</p>
												</li>

												<li className={'line-break'}></li>

												<li>
													<p> Truyền thông-Tin tức </p>
													<p>Score:0.04</p>
												</li>

												<li>
													<p> Kinh tế-chiến lược </p>
													<p>Score:0.03</p>
												</li>
												<li className={'line-break'}></li>
											</ul>
										</div>
									</div>
									<div className="jumbotron">
										<form>
											<div className={'col-sm-6'}>
												<input className="form-control"
															 type="text"
															 placeholder="Enter url here for testing "
															 name="url"/>
											</div>
											<div className={'col-sm-6'}>
												<input className="form-control" style={{ color: 'green' }} type="submit" value=">>> Go >>>"/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
