import React, { Component } from 'react';
import { IndicatorContext } from "../../context/indidator";
import logo from '../../images/AI4U.png';
import { Link, withRouter } from 'react-router-dom';
import { Links } from "../constant/nav-links";

const SubMenu = (props) => {
	return (
		<ul className={'sub-menu'}>
			{
				props.links.map((l, index) => {
					return (
						<li key={'child_' + index}
								onClick={props.click}>
							<Link to={l.to}>
								{l.title}
							</Link>
						</li>
					)
				})
			}
		</ul>
	)
};

export class Header extends Component {

	constructor(props) {
		super(props);

		// refs
		this.mainMenu = React.createRef();
		this.navbar = React.createRef();

		this.state = {
			menuExtraClass: ''
		};

		this.onClickToggleCollapse = this.onClickToggleCollapse.bind(this);

		this.props.history.listen((location, action) => {
			this.handleWindowScroll();
		});
	}


	handleWindowScroll() {

		if (this.context.introHeight === 0) {
			return;
		}

		if (window.scrollY >= this.context.introHeight - 45) {
			this.setState({
				menuExtraClass: 'menu-scroll'
			});
		} else {
			this.setState({
				menuExtraClass: ''
			});
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleWindowScroll.bind(this));
		this.handleWindowScroll();
	}

	onClickToggleCollapse(event, link) {
		if (window.innerWidth > 767) {
			return;
		}

		if (link && link.children) {
			return;
		}

		const isOpened = this.navbar.current.classList.contains('in');
		if (isOpened) {
			this.mainMenu.current.style.backgroundColor = 'inherit';
			this.navbar.current.classList.remove('in');
		} else {
			this.mainMenu.current.style.backgroundColor = '#3d3325';
			this.navbar.current.classList.add('in');
		}
	}

	showIndicator() {
		this.context.toggle();

		setTimeout(() => {
			this.context.toggle();
		}, 500);
	}

	render() {
		return (
			<header id="main_menu" className="header">
				<div className={'main_menu_bg navbar-fixed-top ' + this.state.menuExtraClass}
						 ref={this.mainMenu}>
					<div className="container">
						<div className="row">
							<div className="nave_menu wow fadeInUp animated" data-wow-duration="1s"
									 style={{ visibility: 'visible', 'animationDuration': '1s', 'animationName': 'fadeInUp' }}>
								<nav className="navbar navbar-default"
										 id="navmenu">
									<div className="container-fluid">
										<div className="navbar-header">
											<a className="navbar-brand" href="https://ai4u.io">
												<img src={logo} alt=""/>
											</a>

											<button aria-controls="bs-navbar"

															aria-expanded="false"
															className="navbar-toggle collapsed"
															onClick={this.onClickToggleCollapse}
															data-target="#bs-navbar"
															data-toggle="collapse" type="button"><span className="sr-only">Toggle navigation</span>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
											</button>
										</div>

										<div className="collapse navbar-collapse"
												 ref={this.navbar}
												 id="bs-navbar">
											<ul className="nav navbar-nav navbar-right">
												{
													Links.map((l, index) => {
														return (
															<li key={'parent' + index}
																	className={'item-link'}
																	onClick={(e) => this.onClickToggleCollapse(e, l)}>
																{
																	!l.children ?
																		<Link to={l.to || '/'}
																					onClick={this.showIndicator.bind(this)}>
																			{l.title}
																		</Link>
																		:
																		(
																			<span>
																				{l.title}
																				<span className={'fa fa-caret-down'}
																							style={{marginLeft: '5px'}} />

																				<SubMenu links={l.children}
																								 click={this.onClickToggleCollapse}
																				/>
																			</span>
																		)
																}
															</li>
														)
													})
												}
											</ul>
										</div>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

Header.contextType = IndicatorContext;
export default withRouter(Header);
