import React, {Component} from 'react';

export class FooterCopyRight extends Component {
	render() {
		return (
			<footer id="coppyrights" className="coppyrights">
				<div className="container-fluid">
					<div className="row">
						<div className="coppyright">
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
