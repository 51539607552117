import React, { Component } from 'react';

export class ScrollUp extends Component {
	render() {
		return (
			<div className="scrollup"
					 onClick={
						 window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
					 }>
				<a style={{cursor: 'pointer'}}><i className="fa fa-chevron-up"></i></a>
			</div>
		);
	}
}
