import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Footer } from "./components/layouts/footer";
import { FooterCopyRight } from "./components/layouts/footer-copyright";
import Header from "./components/layouts/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/responsive.css';
import './styles/stylesheet.css';
import './styles/plugins.css';
import './styles/style.css';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { ScrollUp } from "./components/layouts/scroll-up";
import { Index } from "./components/pages";
import { About } from "./components/pages/about";
import { Blog } from "./components/pages/blog";
import { CustomerPrediction } from "./components/pages/customer-prediction";
import { InterestSolution } from "./components/pages/interest-solution";
import { PowerMarketing } from "./components/pages/power-marketing";
import RecommendationSystem from "./components/pages/recommendation-system";
import { Recommender } from "./components/pages/recommender";
import { RequestDemo } from "./components/pages/request-demo";
import { TopicModelling } from "./components/pages/topic-modeling";
import { UserProfile } from "./components/pages/user-profile";
import { IndicatorContext } from "./context/indidator";
import { CookiesProvider } from 'react-cookie';

export class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			indicator: {
				show  : false,
				toggle: () => {
					this.toggleIndicator()
				},
				introHeight: 0,
				setIntroHeight: (height) => {
					this.setIntroHeight(height)
				}
			}

		}
	}

	setIntroHeight(height) {
		this.setState({
			...this.state,
			indicator: {
				...this.state.indicator,
				introHeight: height
			}
		});
	}

	toggleIndicator() {
		this.setState(prevState => {
			return {
				...prevState,
				indicator: {
					...prevState.indicator,
					show: !prevState.indicator.show
				}
			};
		});
	}

	render() {
		return (
			<CookiesProvider>
				<IndicatorContext.Provider value={this.state.indicator}>
					<Router>
						<div>
							<Header/>
							<Route path="/" exact component={Index}/>

							{/*Solutions*/}
							<Route path="/solutions/recommender" component={Recommender}/>
							<Route path="/solutions/customer-prediction" component={CustomerPrediction}/>
							<Route path="/solutions/interest-solution" component={InterestSolution}/>
							{/*<Route path="/solutions/topic-modeling" component={TopicModelling}/>*/}

							{/*Demo*/}
							<Route path="/live-demo" component={RecommendationSystem}/>
							<Route path="/request-trial" component={RequestDemo}/>

							<Route path="/about" component={About}/>
							<Route path="/blog" component={Blog}/>
							<Footer/>
							<FooterCopyRight/>
							<ScrollUp/>
						</div>
					</Router>

					<IndicatorContext.Consumer>
						{
							({ show }) => {
								if (show) {
									return (
										<div className="preloader">
											<div className="loaded">&nbsp;</div>
										</div>
									)
								}
							}
						}
					</IndicatorContext.Consumer>
				</IndicatorContext.Provider>
			</CookiesProvider>
		);
	}
}
